import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import project1 from "../assets/images/kr-project-01-pic.jpg";
import project2 from "../assets/images/kr-project-02-pic.jpg";
import project3 from "../assets/images/kr-project-03-pic.jpg";
import project4 from "../assets/images/kr-project-04-pic.jpg";
import project5 from "../assets/images/kr-project-05-pic.jpg";
import project6 from "../assets/images/kr-project-06-pic.jpg";
import "./Portfolio.css"; // Import the CSS file for styling
import HelmetWrapper from "../components/common/HelmetWrapper";

const Portfolio = () => {
  return (
    <div className="portfolio-page">
      <HelmetWrapper title="Portfolio" />
      <CommonBanner title="Portfolio" />
      <section className="intro-section">
        <Container className="intro-content">
          <h1>Our Portfolio</h1>
          <p>
            Explore our portfolio to see the diverse range of projects we have
            worked on. Our team has delivered outstanding results across various
            industries, showcasing our expertise and creativity.
          </p>
        </Container>
      </section>
      <section className="portfolio-section">
        <Container>
          <Row>
            {/* Portfolio Items */}
            <Col md={4} className="mb-4">
              <div className="portfolio-item">
                <img
                  src={project1}
                  alt="Portfolio Item 1"
                  className="portfolio-img"
                />
                <div className="portfolio-info">
                  <h3 className="portfolio-title">
                    Digital India Payments Limited
                  </h3>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="portfolio-item">
                <img
                  src={project2}
                  alt="Portfolio Item 2"
                  className="portfolio-img"
                />
                <div className="portfolio-info">
                  <h3 className="portfolio-title">Atrina Technologies</h3>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="portfolio-item">
                <img
                  src={project3}
                  alt="Portfolio Item 3"
                  className="portfolio-img"
                />
                <div className="portfolio-info">
                  <h3 className="portfolio-title">Orbit Worldwide Express</h3>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="portfolio-item">
                <img
                  src={project4}
                  alt="Portfolio Item 3"
                  className="portfolio-img"
                />
                <div className="portfolio-info">
                  <h3 className="portfolio-title">NeuroPharmac Journal</h3>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="portfolio-item">
                <img
                  src={project5}
                  alt="Portfolio Item 3"
                  className="portfolio-img"
                />
                <div className="portfolio-info">
                  <h3 className="portfolio-title">
                    Journal of Orthopaedic Case Reports
                  </h3>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="portfolio-item">
                <img
                  src={project6}
                  alt="Portfolio Item 3"
                  className="portfolio-img"
                />
                <div className="portfolio-info">
                  <h3 className="portfolio-title">Support Better</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Portfolio;
