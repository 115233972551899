// src/components/MissionVisionValues.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import missionIcon from "../assets/images/kr-mission-icon-blue.png";
import visionIcon from "../assets/images/kr-vision-icon-green.png";
import valuesIcon from "../assets/images/kr-values-icon-red.png";
import "./MissionVisionValues.css";

const MissionVisionValues = () => {
  return (
    <section className="mission-vision-values">
      <Container>
        <Row>
          <Col md={4}>
            <div className="mv-section kr-mission-section">
              <div className="mv-icon">
                <img src={missionIcon} alt="Our Mission" />
              </div>

              <h3>Our Mission</h3>
              <p>
                Our mission is to deliver the best solutions that empower our
                clients to achieve their goals. We strive for excellence in
                everything we do.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="mv-section kr-vision-section">
              <div className="mv-icon">
                <img src={visionIcon} alt="Our Vision" />
              </div>

              <h3>Our Vision</h3>
              <p>
                Our vision is to be the leading provider of innovative and
                customized solutions, recognized for our commitment to quality
                and customer satisfaction.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="mv-section kr-values-section">
              <div className="mv-icon">
                <img src={valuesIcon} alt="Our Values" />
              </div>
              <h3>Our Values</h3>
              <p>
                We value integrity, excellence, and teamwork. Our values guide
                our actions and decisions, ensuring we always act in the best
                interests of our clients and employees.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MissionVisionValues;
