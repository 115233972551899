import React from "react";
import missionIcon from "../assets/images/kr-mission-icon.png";
import visionIcon from "../assets/images/kr-vision-icon.png";
import valuesIcon from "../assets/images/kr-values-icon.png";
import teamMember1 from "../assets/images/kr-our-team-pic.jpg";
import "./About.css"; // Import the CSS file
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import IntroSection from "../components/common/IntroSection";
import HelmetWrapper from "../components/common/HelmetWrapper";
import "bootstrap-icons/font/bootstrap-icons.css";

const teamMembers = [
  {
    icon: "bi bi-person",
    name: "Mr. Ashish",
    role: "Founder",
    image: teamMember1,
    bio: "Ashish is the visionary leader of our company, driving innovation and excellence.",
  },
  {
    icon: "bi bi-person",
    name: "Shivam Yadav",
    role: "Software Engineer",
    image: teamMember1,
    bio: "Shivam is the tech guru, overseeing our technological advancements and strategies.",
  },
  {
    icon: "bi bi-person",
    name: "Abhishek Singh",
    role: "UI/UX Designer",
    image: teamMember1,
    bio: "Abhishek is dedicated to creating intuitive and engaging user experiences, ensuring our designs are both visually appealing and user-friendly.",
  },
];

const About = () => {
  return (
    <div className="about-page">
      <HelmetWrapper title="About Us" />
      <CommonBanner title="About Us" />
      <IntroSection
        title="About the Krishabh"
        description="We bring you the finest services and
              solutions tailored to meet your needs. Our team of experts is
              dedicated to ensuring your success through innovative and
              customized approaches."
      />
      <section className="about-section">
        <Container>
          <Row>
            <Col md={4}>
              <div className="about-item">
                <img src={missionIcon} alt="Mission" className="about-icon" />
                <h3>Our Mission</h3>
                <p>
                  Our mission is to deliver the best solutions that empower our
                  clients to achieve their goals. We strive for excellence in
                  everything we do.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="about-item">
                <img src={visionIcon} alt="Vision" className="about-icon" />
                <h3>Our Vision</h3>
                <p>
                  Our vision is to be the leading provider of innovative and
                  customized solutions, recognized for our commitment to quality
                  and customer satisfaction.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="about-item">
                <img src={valuesIcon} alt="Values" className="about-icon" />
                <h3>Our Values</h3>
                <p>
                  We value integrity, excellence, and teamwork. Our values guide
                  our actions and decisions, ensuring we always act in the best
                  interests of our clients and employees.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="team-section">
        <Container>
          <h2>Our Team</h2>
          <Row>
            {teamMembers.map((member, index) => (
              <Col md={4} key={index} className="mb-4">
                <div className="team-member">
                  <i className={member.icon}></i>
                  <h3 className="team-member-name">{member.name}</h3>
                  <p className="team-member-role">{member.role}</p>
                  <p className="team-member-bio">{member.bio}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
