// src/components/PortfolioSection.js
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
// Import images
import project1 from "../assets/images/kr-project-01-pic.jpg";
import project2 from "../assets/images/kr-project-02-pic.jpg";
import project3 from "../assets/images/kr-project-03-pic.jpg";
import project4 from "../assets/images/kr-project-04-pic.jpg";
import project5 from "../assets/images/kr-project-05-pic.jpg";
import project6 from "../assets/images/kr-project-06-pic.jpg";

const portfolioItems = [
  {
    title: "Digital India Payments Limited",
    image: project1,
  },
  {
    title: "Atrina Technologies",
    image: project2,
  },
  {
    title: "Orbit Worldwide Express",
    image: project3,
  },
  {
    title: "NeuroPharmac Journal",
    image: project4,
  },
  {
    title: "Journal of Orthopaedic Case Reports",
    image: project5,
  },
  {
    title: "Support Better",
    image: project6,
  },
];

const PortfolioSection = () => {
  return (
    <section className="portfolio-section">
      <Container>
        <h2 className="section-title">Our Works</h2>
        <Row>
          {portfolioItems.map((item, index) => (
            <Col md={4} key={index}>
              <Card className="portfolio-card">
                <Card.Img variant="top" src={item.image} alt={item.title} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PortfolioSection;
