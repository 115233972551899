import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import "./Service.css"; // Import the CSS file for styling
import IntroSection from "../components/common/IntroSection";
import HelmetWrapper from "../components/common/HelmetWrapper";

const Service = () => {
  return (
    <div className="services-page">
      <HelmetWrapper title="Our Services" />
      <CommonBanner title="Services" />
      <IntroSection
        title="Our Services"
        description="At Krishabh, we offer a range of services designed to help you achieve your business goals. Our team of experts is committed to providing innovative and tailored solutions to meet your unique needs."
      />
      <section className="service-section">
        <Container>
          <Row>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <h2>Website Design</h2>
                <p>
                  We create visually stunning and user-friendly websites that
                  captivate your audience. Our design process focuses on
                  intuitive user experiences and responsive designs that reflect
                  your brand's identity perfectly.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <h2>Website Development</h2>
                <p>
                  Our development team builds robust and scalable websites using
                  the latest technologies. We ensure that your website looks
                  great and performs optimally, with tailored features and
                  functionality.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <h2>Mobile App Development</h2>
                <p>
                  Expand your reach with a mobile app designed to provide an
                  exceptional user experience on smartphones and tablets. Our
                  development team builds high-performance apps that are both
                  functional and engaging.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <h2>UI/UX Design</h2>
                <p>
                  Establish a strong brand identity with a custom-designed logo.
                  We create memorable and versatile logos that capture the
                  essence of your brand and leave a lasting impression on your
                  audience.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <h2>Graphic Design</h2>
                <p>
                  Our graphic design services include creating visually
                  appealing materials such as brochures, banners, and social
                  media graphics. We deliver designs that communicate your
                  message effectively and attract your target audience.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <h2>Website Maintenance</h2>
                <p>
                  Keep your website running smoothly with our maintenance
                  services. We provide regular updates, security patches, and
                  technical support to ensure that your website remains secure
                  and up-to-date.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Service;
